import { withRedux } from './redux/redux';
import {
  buildApiClientForBrowser,
  buildApiClientFromNextJsContext,
} from './api/apiClient.js';
import React, { useEffect, useMemo } from 'react';
import {
  reloadUserAction,
  USER_MAX_AGE,
  userLoggedAction,
} from './redux/modules/authentication';
import { useDispatch, useSelector } from 'react-redux';
import Router from 'next/router';
import querystring from 'querystring';
import refreshUser from './api/refreshUser';
import { isUserOrganiser } from './components/app/users/isGranted';
import * as Sentry from '@sentry/node';
import { NextComponentType, NextPageContext } from 'next';
import { User } from '@/types/user';
import { AppContext } from 'next/app';
import { UserWithRefreshedAt } from '@/types/core/User';
import { ReduxRootState } from '@/types/store/ReduxRootState';

interface WithAuthOptions {
  requireLoggedUser?: boolean;
  requireOrganiser?: boolean;
  additionalReducers?: any;
  additionalSagas?: any;
}

type PageContext = NextPageContext & {
  reduxStore: any;
};

type GetInitialPropsFunction = (ctx: AppContext) => Promise<{}>;

function getComponentInitialProps(
  component: React.ComponentType & {
    getInitialProps?: GetInitialPropsFunction;
  },
  ctx: AppContext
) {
  return component.getInitialProps ? component.getInitialProps(ctx) : {};
}

function getAuthenticationToken(ctx: NextPageContext) {
  if (typeof window === 'undefined') {
    const cookie = require('cookie');
    const cookies = cookie.parse(ctx.req.headers.cookie || '');

    return cookies.JWT;
  }

  return false;
}

async function fetchUser(apiClient: any, ctx: NextPageContext): Promise<User> {
  const token = getAuthenticationToken(ctx);
  if (!token) {
    return null;
  }

  let res = await refreshUser(apiClient);
  if (res.status === 200) {
    return res.json();
  }
  return null;
}

export default function withUser(
  WrappedComponent: React.ComponentType,
  options: WithAuthOptions = {}
) {
  const Component: NextComponentType = (
    props: React.JSX.IntrinsicAttributes
  ) => {
    const user = useSelector(
      (state: ReduxRootState) => state.authentication.user
    );

    const isOrganiser = useMemo<boolean>(() => {
      return isUserOrganiser(user);
    }, [user]);

    const dispatch = useDispatch();
    useEffect(() => {
      if (Sentry) {
        Sentry.setExtra('user', {
          id: user?.id,
          email: user?.email,
        });
      }
    }, [user]);

    useEffect(() => {
      const doRefreshUser = async () => {
        const apiClient = await buildApiClientForBrowser();
        const refreshedUser: UserWithRefreshedAt = await refreshUser(
          apiClient
        ).then(res => res.json());
        dispatch(reloadUserAction(refreshedUser));
      };

      if (user && Date.now() - user.refreshedAt > USER_MAX_AGE) {
        doRefreshUser();
      }
    }, [user]);

    if (!user && options.requireLoggedUser) {
      return null;
    }
    if (!isOrganiser && options.requireOrganiser) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  Component.getInitialProps = async (ctx: PageContext) => {
    const apiClient = await buildApiClientFromNextJsContext(ctx);
    let user = await fetchUser(apiClient, ctx);
    const { dispatch } = ctx.reduxStore;

    if (typeof window === 'undefined') {
      dispatch(userLoggedAction(user));
    }
    const storeState = ctx.reduxStore.getState();

    if (
      (options.requireLoggedUser || options.requireOrganiser) &&
      !storeState.authentication.user
    ) {
      const query = {
        backPathname: ctx.pathname,
        backAsPath: ctx.asPath,
      };
      for (let queryEntry of Object.entries(ctx.query)) {
        query[`savedQuery${queryEntry[0]}`] = queryEntry[1];
      }

      if (ctx.res) {
        let path = `/se-connecter?${querystring.encode(query)}`;

        ctx.res.writeHead(302, {
          Location: path,
        });
        ctx.res.end();
        return;
      }
      let pathname = '/se-connecter';
      let as = '/se-connecter';

      await Router.push(
        {
          pathname,
          query: {
            ...query,
          },
        },
        as
      );

      // wait on an unfinishable promise to avoid the render of the current component
      await new Promise(() => {});
    }

    return getComponentInitialProps(WrappedComponent, ctx as any);
  };

  return withRedux(Component, {
    additionalReducers: options.additionalReducers,
    additionalSagas: options.additionalSagas,
  });
}
